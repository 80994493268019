import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { history } from '../../history';
import { UserContext } from '../../context';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Menu = () => {
  const context = useContext(UserContext);
  const logado = context.isLogger;

  const logout = () => {
    localStorage.removeItem('delphireact-token');
    context.alterLogin(false);
    history.push('/login');
  };

  if (logado) {
    return (
      <div>
        <nav className='navbar navbar-expand-lg navbar-dark bg-dark fixed-top'>
          <button className='navbar-brand h2'>Compsoft - Sistemas</button>          
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav'>
              <li className='nav-item'>                
                  <Link className='nav-link h6' to={'/'}>Home</Link>                
              </li>
              <li className='nav-item'>                
                  <Link className='nav-link h6' to={'/pdv'}>PDV</Link>                
              </li>              
              <li className='nav-item'>                
                  <Link className='nav-link h6' to={'/lics'}>Licenças</Link>                
              </li>
              <li className='nav-item'>                
                  <Link className='nav-link h6' to={'/ver'}>Versões</Link>                
              </li>
              <li className='nav-item'>                
                  <Link className='nav-link h6' to={'/csapi'}>Produtos AZ</Link>                
              </li>
              <li className='nav-item'>
                <button className='nav-link h6' onClick={() => logout()}>
                  Sair
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  } else {
    return null;
  }
};

export default Menu;
