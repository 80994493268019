import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Listlic from './components/lics/list';
import Listver from './components/versao/ver';
import Principal from './components/principal';
import listpdv from './components/pdv/list';
import Listpdvexception from './components/pdvexception/list';
import Login from './pages/login';
import api from './components/api/api';
import csapi from './components/csapi/csapi';
import PrivateRoute from './PrivateRoute';




const Routes = () => (
        <Switch>
            <PrivateRoute exact path='/' component={Principal} />
            <PrivateRoute exact path='/lics' component={Listlic} />            
            <PrivateRoute exact path='/ver' component={Listver} />                        
            <PrivateRoute exact path='/pdv' component={listpdv} />            
            <PrivateRoute exact path='/pdvexception' component={Listpdvexception} />
            <PrivateRoute exact path='/api' component={api} />            
            <PrivateRoute exact path='/csapi' component={csapi} />                        
            <Route exact path='/login' component={Login} />
        </Switch>
)

export default Routes;