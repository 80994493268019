import React, { useState, createContext } from 'react';

export const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [data, setData] = useState({
        logger: !!localStorage.getItem('delphireact-token'),
        userRole: localStorage.getItem('user-role') || null, // Adiciona a função do usuário
    });

    const isLogger = data.logger;
    const userRole = data.userRole;

    const alterLogin = (value, role = null) => {
        setData({ ...data, logger: value, userRole: role });
    };

    return (
        <UserContext.Provider value={{ isLogger, userRole, alterLogin }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
