import React from 'react';
import axios from 'axios';

export default class Monitoramento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPingOk: null,
    };
  }

  componentDidMount() {
    this.handlePingClick();
    this.pingInterval = setInterval(this.handlePingClick, 10 * 10 * 200);
  }

  componentWillUnmount() {
    clearInterval(this.pingInterval);
  }

  handlePingClick = async () => {
    try {
      await axios.get('https://v1-csapi.compsoft.com.br:825/ping');
      this.setState({ isPingOk: true });
    } catch (error) {
      this.setState({ isPingOk: false });
    }
  };

  handleRestartClick = async () => {
    // Exibe uma caixa de diálogo de confirmação
    const userConfirmed = window.confirm('Tem certeza de que deseja reiniciar o servidor?');

    if (userConfirmed) {
    
    const credentials = btoa('Portal:Portal1163'); // Codifica as credenciais para Base64

    try {
      // Faz uma chamada para reiniciar o servidor com autenticação básica
      await axios.post('https://v1-csapi.compsoft.com.br:824/taskstart/api_monitoramento', null, {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      console.log('Reiniciando servidor...');
      // Atualiza o estado após o reinício (se necessário)
      this.setState({ isPingOk: null });
    } catch (error) {
      console.error('Erro ao reiniciar o servidor:', error);
    }
  }
};

  render() {
    const { isPingOk } = this.state;
    const statusColor = isPingOk === null ? 'gray' : isPingOk ? 'green' : 'red';

    return (
      <td style={{ color: statusColor }}>
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
        <div className='btn btn-sm btn-secondary'>
        {isPingOk === null
          ? 'Aguarde'
          : isPingOk
          ? 'On'
          : 'Off'}
        </div>
        <div className='btn btn-success btn-sm' onClick={this.handleRestartClick}><i className="fa-solid fa-arrows-rotate"></i></div>
        </div>
      </td>
    );
  }
}
