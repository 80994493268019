import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Modal from 'react-modal';
import './modal.css';

const axiosInstance = axios.create({
  baseURL: 'https://v1-csapi.compsoft.com.br:823/pdv_exception',
  auth: { username: 'Portal', password: 'Portal1163' },
});

function formatarData(dataInput) {
  return new Date(dataInput).toLocaleString('pt-BR', { timeZone: 'UTC' });
}

function capitalizeFirstLetter(str) {
  return str.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase());
}

const ListaRegistros = () => {
  const [state, setState] = useState({
    registrosOriginais: [],
    registros: [],
    erro: false,
    modalIsOpen: false,
    modalData: [],
    ordenacao: 'desc',
    ordenacaoCampo: 'recepcao',
    filtroProjeto: ' Todos',
    projetos: [],
    dataAtualPorExtenso: '',
    ultimosDiasData: null, // Novo estado para "Últimos dias"
  });

  const carregarDadosIniciais = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/false');
      const registrosOriginais = response.data;
      const projetosUnicos = Array.from(new Set(registrosOriginais.map((registro) => registro.projeto)))
        .filter((projeto) => projeto !== 'Branco' && projeto !== 'Extra');
      setState((prevState) => ({
        ...prevState,
        registrosOriginais,
        projetos: [' Todos', ...projetosUnicos].sort(),
      }));
    } catch (error) {
      console.error('Erro ao carregar dados iniciais:', error);
      setState((prevState) => ({ ...prevState, erro: true }));
    }
  }, []);

  const filtrarPorProjeto = useCallback((projeto) => {
    setState((prevState) => ({
      ...prevState,
      registros: projeto === ' Todos' ? prevState.registrosOriginais : prevState.registrosOriginais.filter((registro) => registro.projeto === projeto),
      filtroProjeto: projeto,
    }));
  }, []);

  const excluirRegistroDoModal = useCallback(async (id) => {
    try {
      await axiosInstance.post(`/${id}`, '');
      setState((prevState) => ({
        ...prevState,
        modalData: prevState.modalData.filter((registro) => registro.controle !== id),
      }));
    } catch (error) {
      console.error('Erro ao excluir o registro:', error);
    }
  }, []);

  const excluirTodos = useCallback(async () => {
    try {
      const { modalData } = state;
      for (const registro of modalData) {
        await excluirRegistroDoModal(registro.controle);
      }
      closeModal();
    } catch (error) {
      console.error('Erro ao excluir todos os registros:', error);
    }
  }, [state.modalData, excluirRegistroDoModal]);

  const exibirDetalhes = useCallback((recordsDoCnpj) => {
    setState((prevState) => ({
      ...prevState,
      modalData: recordsDoCnpj,
      modalIsOpen: true,
    }));
  }, []);

  const closeModal = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      modalIsOpen: false,
    }));
    carregarDadosIniciais();
  }, [carregarDadosIniciais]);

  const groupByCnpj = useCallback((records, ordenacaoCampo, ordem) => {
    const groupedData = records.reduce((acc, record) => {
      const { cnpj } = record;
      acc[cnpj] = (acc[cnpj] || []).concat(record);
      return acc;
    }, {});

    return Object.entries(groupedData)
      .map(([cnpj, records]) => ({
        cnpj,
        records,
        count: records.length,
      }))
      .sort((a, b) => {
        if (ordenacaoCampo === 'recepcao') {
          return ordem === 'asc' ? new Date(a.records[0].recepcao) - new Date(b.records[0].recepcao) : new Date(b.records[0].recepcao) - new Date(a.records[0].recepcao);
        }
        return ordem === 'asc' ? a.count - b.count : b.count - a.count;
      });
  }, []);

  const fetchUltimosDias = useCallback(async (cnpj) => {
    try {
      const response = await axiosInstance.get(`https://v1-csapi.compsoft.com.br:823/exception/${cnpj}`);
      setState((prevState) => ({
        ...prevState,
        ultimosDiasData: response.data,
      }));
    } catch (error) {
      console.error('Erro ao buscar dados dos últimos dias:', error);
    }
  }, []);

  useEffect(() => {
    carregarDadosIniciais();
    const interval = setInterval(carregarDadosIniciais, 30000);

    const dataAtual = new Date();
    const dataFormatada = format(dataAtual, "EEEE, d 'de' MMMM 'de' yyyy", { locale: ptBR });
    setState((prevState) => ({
      ...prevState,
      dataAtualPorExtenso: dataFormatada,
    }));

    return () => clearInterval(interval);
  }, [carregarDadosIniciais]);

  useEffect(() => {
    filtrarPorProjeto(state.filtroProjeto);
  }, [state.registrosOriginais, state.filtroProjeto, filtrarPorProjeto]);

  const sortedRegistros = groupByCnpj(state.registros, state.ordenacaoCampo, state.ordenacao);

  return (
    <div>
      {state.erro ? (
        <div>Buscando dados no servidor.</div>
      ) : (
        <React.Fragment>
          <div className='h6'>{state.dataAtualPorExtenso}</div>
          <div className='btn-group btn-group-sm'>
            {state.projetos.map((projeto, index) => (
              <div
                key={index}
                className={`btn ${state.filtroProjeto === projeto ? 'btn-success' : 'btn-primary'}`}
                onClick={() => filtrarPorProjeto(projeto)}
              >
                {capitalizeFirstLetter(projeto)}
                <div className='badge badge-custom'>
                  {projeto === ' Todos'
                    ? state.registrosOriginais.length
                    : state.registrosOriginais.filter((registro) => registro.projeto === projeto).length}
                </div>
              </div>
            ))}
          </div>
          <table className='table  font-sm'>
            <thead>
              {/* Cabeçalho da tabela */}
            </thead>
            <tbody>
              {sortedRegistros.length > 0 ? (
                sortedRegistros.map(({ cnpj, records, count }) => (
                  <tr key={cnpj}>
                    <td style={{ width: '2%' }}>
                      <h1>{count}</h1>
                    </td>
                    <td style={{ width: '30%' }}>
                      <p className='small'>{cnpj}</p>
                      <h6>{records[0].nome}</h6>
                      <p className='small'>
                        {records[0].classe} - {records[0].projeto}
                      </p>
                    </td>
                    <td style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: records[0].mensagem }} />                    
                    <td className='limited-cell'>
                      <div className='btn-group '>
                      <button className='btn btn-sm btn-secondary' onClick={() => exibirDetalhes(records)}>
                        Detalhes
                      </button>
                      <button className='btn btn_sm btn-success' onClick={() => fetchUltimosDias(cnpj)}>
                        Últimos
                      </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='6'>Nenhum registro encontrado.</td>
                </tr>
              )}
            </tbody>
          </table>
        </React.Fragment>
      )}
      <Modal
        isOpen={state.modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Detalhes'
        className='react-modal-content'
        overlayClassName='react-modal-overlay'
      >
        <div className='modal-header modal-header-wrapper'>
          <button className='btn btn-primary' onClick={closeModal}>
            Fechar
          </button>
          {localStorage.getItem('user-role') === 'MASTER' && ( // Renderiza condicionalmente o botão "Excluir Todos"
            <button className='btn btn-danger' onClick={excluirTodos}>
              Excluir Todos
            </button>
          )}
        </div>
        {state.modalData.length > 0 ? (
          <div className='modal-table'>
            <table className='table table-hover font-sm data-table'>
              <thead className='table-header'>
                <tr>
                  <th>Classe/ID</th>
                  <th>Erro</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {state.modalData.map((registro, index) => (
                  <tr key={index}>
                    <td className='limited-cell'>
                      <h6>{registro.classe}</h6>
                      <p className='small text-nowrap'>{formatarData(registro.recepcao)}</p>
                    </td>
                    <td style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: registro.mensagem }} />
                    <td>
                    {localStorage.getItem('user-role') === 'MASTER' && ( // Renderiza condicionalmente o botão "Excluir Todos"
     
                      <button className='btn btn-danger' onClick={() => excluirRegistroDoModal(registro.controle)}>
                        Excluir
                      </button>
                    )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>Nenhum registro encontrado.</p>
        )}
      </Modal>
      <Modal
        isOpen={!!state.ultimosDiasData}
        onRequestClose={() => setState((prevState) => ({ ...prevState, ultimosDiasData: null }))}
        contentLabel='Últimos...'
        className='react-modal-content'
        overlayClassName='react-modal-overlay'
      >
        <div className='modal-header'>
          <button className='btn btn-primary' onClick={() => setState((prevState) => ({ ...prevState, ultimosDiasData: null }))}>
            Fechar
          </button>
        </div>
        {state.ultimosDiasData ? (
          <div className='modal-table'>
            <table className='table table-hover font-sm data-table'>
              <thead className='table-header'>
                <tr>
                  <th>Data Evento</th>
                  <th>Mensagem</th>
                  <th>Lido</th>                  
                </tr>
              </thead>
              <tbody>
                {state.ultimosDiasData.map((registro, index) => (
                  <tr key={index}>
                    <td className='limited-cell'>
                      <h6>{registro.classe}</h6>
                      <p className='small text-nowrap'>{formatarData(registro.recepcao)}</p>
                    </td>
                    
                    <td style={{ width: '75%' }} dangerouslySetInnerHTML={{ __html: registro.mensagem }} />
                    <td>
                      {registro.lido === 'S' && (<span>&#10003;</span> )}
                    </td>                    
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>Nenhum dado encontrado.</p>
        )}
      </Modal>
    </div>
  );
};

export default ListaRegistros;