import React, { useState, useEffect } from 'react';

const ServidoresAWS = () => {
  const [serverData, setServerData] = useState([]);

  const fetchServerData = async () => {
    try {
      const response = await fetch('https://v1-csapi.compsoft.com.br:823/aws', {
        headers: {
          Authorization: 'Basic ' + btoa('Portal:Portal1163')
        }
      });
      console.log('Obtendo dados servidores aws');
      const data = await response.json();
      setServerData(data);
    } catch (error) {
      console.error('Error fetching server data:', error);
    }
  };

  useEffect(() => {
    // Fetch initial data
    fetchServerData();

    // Fetch data every 10 minutes
    const intervalId = setInterval(fetchServerData, 10 * 60 * 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);

  const getStatusColor = (status) => {
    if (status === 'ON') {
      return 'green';
    } else if (status === 'OFF') {
      return 'red';
    } else {
      return 'yellow';
    }
  };

  const getLivreColor = (livreText) => {
    const livreMatch = livreText.match(/(\d*,\d+|\d+\.\d+)/);
    const livreValue = livreMatch ? parseFloat(livreMatch[0].replace(',', '.')) : null;

    if (livreValue !== null) {
      if (livreValue < 1.00) {
        return 'red';
      } else if (livreValue >= 1.01 && livreValue < 2.00) {
        return 'orange';
      } else if (livreValue >= 2.00) {
        return 'green';
      }
    }    

    return 'black';
  };

  return (
    <table>
      <tbody>
        {serverData.map((server, index) => {
          // Remover a palavra "Espaço" de server.Total e server.Livre
          const totalText = server.Total.replace('Espaço Total:', '').trim();
          const livreText = server.Livre.replace('Espaço Livre:', 'Disk:').trim();
          const memoriaTotalText = server.MemoriaTotal ? server.MemoriaTotal.replace('Memória Total:', '').trim() : '';
          const memoriaLivreText = server.MemoriaLivre ? server.MemoriaLivre.replace('Memória Livre:', 'Mem:').trim() : '';

          // Formatar a memória no estilo "Mem: Valor Livre - Valor Total"
          const memoriaText = memoriaLivreText && memoriaTotalText ? `${memoriaLivreText} - ${memoriaTotalText}` : '';

          return (
            <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
              <td style={{ padding: '4px 8px', fontFamily: 'Arial, sans-serif', fontSize: 12 }}>{server.servidor}</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ padding: '1px 1px', fontFamily: 'Arial, sans-serif', fontSize: 10, lineHeight: '1' }}><span style={{ color: getLivreColor(livreText) }}>{livreText}</span> - {totalText}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: '2px 1px', fontFamily: 'Arial, sans-serif', fontSize: 10, lineHeight: '1' }}>
                        <span style={{ color: getLivreColor(memoriaLivreText) }}>{memoriaText}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style={{ color: getStatusColor(server.status), padding: '4px 12px', fontFamily: 'Arial, sans-serif', fontSize: 12 }}>{server.status}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ServidoresAWS;
