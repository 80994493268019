import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './modal.css';

const axiosInstance = axios.create({
  baseURL: 'https://v1-csapi.compsoft.com.br:823',
  auth: { username: 'Portal', password: 'Portal1163' },
});

function formatarData(dataInput) {
  return new Date(dataInput).toLocaleString('pt-BR', { timeZone: 'UTC' });
}

function capitalizeFirstLetter(str) {
  return str.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase());
}

const ListaRegistrosDev = () => {
  const [state, setState] = useState({
    registrosOriginais: [],
    registros: [],
    erro: false,
    modalIsOpen: false,
    modalData: [],
    filtroProjeto: ' Todos',
    projetos: [],
    dataAtualPorExtenso: '',
    exclusaoEmAndamento: false,
  });

  const carregarDadosIniciais = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/dev_exception');
      const registrosOriginais = response.data;
      const projetosUnicos = Array.from(new Set(registrosOriginais.map((registro) => registro.projeto)));
      setState((prevState) => ({
        ...prevState,
        registrosOriginais,
        projetos: [' Todos', ...projetosUnicos].sort(),
      }));
    } catch (error) {
      console.error('Erro ao carregar dados iniciais:', error);
      setState((prevState) => ({ ...prevState, erro: true }));
    }
  }, []);

  const filtrarPorProjeto = useCallback((projeto) => {
    setState((prevState) => ({
      ...prevState,
      registros: projeto === ' Todos' ? prevState.registrosOriginais : prevState.registrosOriginais.filter((registro) => registro.projeto === projeto),
      filtroProjeto: projeto,
    }));
  }, []);

  const excluirRegistroDoModal = useCallback(async (id) => {
    try {
      await axiosInstance.get(`/dev_exception_del/${id}`);
    } catch (error) {
      console.error('Erro ao excluir o registro:', error);
      throw error;
    }
  }, []);

  const limparRegistros = useCallback(async (records) => {
    const ids = records.map(record => record.controle);
    try {
      setState((prevState) => ({ ...prevState, exclusaoEmAndamento: true }));
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simula a exclusão com atraso
      for (const id of ids) {
        await excluirRegistroDoModal(id);
      }
      const novosRegistros = state.registrosOriginais.filter(record => !ids.includes(record.controle));
      setState((prevState) => ({
        ...prevState,
        registrosOriginais: novosRegistros,
        exclusaoEmAndamento: false,
      }));
      filtrarPorProjeto(state.filtroProjeto);
    } catch (error) {
      console.error('Erro ao limpar registros:', error);
      setState((prevState) => ({ ...prevState, exclusaoEmAndamento: false }));
    }
  }, [excluirRegistroDoModal, filtrarPorProjeto, state.filtroProjeto, state.registrosOriginais]);

  const exibirDetalhes = useCallback((recordsDoCnpj) => {
    setState((prevState) => ({
      ...prevState,
      modalData: recordsDoCnpj,
      modalIsOpen: true,
    }));
  }, []);

  useEffect(() => {
    carregarDadosIniciais();
    const interval = setInterval(carregarDadosIniciais, 10000);

    const dataAtual = new Date();
    const dataFormatada = format(dataAtual, "EEEE, d 'de' MMMM 'de' yyyy", { locale: ptBR });
    setState((prevState) => ({
      ...prevState,
      dataAtualPorExtenso: dataFormatada,
    }));

    return () => clearInterval(interval);
  }, [carregarDadosIniciais]);

  useEffect(() => {
    filtrarPorProjeto(state.filtroProjeto);
  }, [state.registrosOriginais, state.filtroProjeto, filtrarPorProjeto]);

  const closeModal = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      modalIsOpen: false,
    }));
    carregarDadosIniciais();
  }, [carregarDadosIniciais]);

  const groupByCnpj = useCallback((records, ordenacaoCampo, ordem) => {
    const groupedData = records.reduce((acc, record) => {
      const { cnpj } = record;
      acc[cnpj] = (acc[cnpj] || []).concat(record);
      return acc;
    }, {});

    return Object.entries(groupedData)
      .map(([cnpj, records]) => ({
        cnpj,
        records,
        count: records.length,
      }))
      .sort((a, b) => {
        if (ordenacaoCampo === 'recepcao') {
          const dateA = new Date(a.records[0].recepcao);
          const dateB = new Date(b.records[0].recepcao);
          return ordem === 'asc' ? dateA - dateB : dateB - dateA;
        } else {
          return ordem === 'asc' ? a.count - b.count : b.count - a.count;
        }
      });
  }, []);

  const sortedRegistros = groupByCnpj(state.registros, 'recepcao', 'desc');

  return (
    <div className='container-sm'>
      {state.erro ? (
        <div>Buscando dados no servidor.</div>
      ) : (
        <React.Fragment>
          <div className='h6'>{state.dataAtualPorExtenso} - Erros Disparados em testes/desenvolvimento</div>
          <div className='btn-group btn-group-sm'>
            {state.projetos.map((projeto, index) => (
              <div
                key={index}
                className={`btn ${state.filtroProjeto === projeto ? 'btn-success' : 'btn-primary'}`}
                onClick={() => filtrarPorProjeto(projeto)}
              >
                {capitalizeFirstLetter(projeto)}
                <div className='badge badge-custom'>
                  {projeto === ' Todos'
                    ? state.registrosOriginais.length
                    : state.registrosOriginais.filter((registro) => registro.projeto === projeto).length}
                </div>
              </div>
            ))}
          </div>
          <table className='table table-hover font-sm'>
            <thead>{/* Restante do código */}</thead>
            <tbody>
              {sortedRegistros.length > 0 ? (
                sortedRegistros.map(({ cnpj, records, count }) => (
                  <React.Fragment key={cnpj}>
                    <tr>
                      <td style={{ width: '2%' }}>
                        <h1>{count}</h1>
                      </td>
                      <td style={{ width: '30%' }}>
                        <p className='small'>{cnpj}</p>
                        <h6>{records[0].nome}</h6>
                        <p className='small'>
                          {records[0].classe} - {records[0].projeto}
                        </p>
                      </td>
                      <td style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: records[0].mensagem }} />
                      <td className='limited-cell'>
                        <div className='d-flex flex-column'>
                          <button
                            className='btn btn-secondary mb-2'
                            onClick={() => exibirDetalhes(records)}
                          >
                            Detalhes
                          </button>
                          <button
                            className='btn btn-danger'
                            onClick={() => limparRegistros(records)}
                          >
                            Limpar
                          </button>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan='6'>Nenhum registro encontrado.</td>
                </tr>
              )}
            </tbody>
          </table>
        </React.Fragment>
      )}
      <Modal
        isOpen={state.modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Detalhes'
        className='react-modal-content'
        overlayClassName='react-modal-overlay'
      >
        <div className='modal-header modal-header-wrapper'>
          <button className='btn btn-primary' onClick={closeModal}>
            Fechar
          </button>
        </div>
        {state.modalData.length > 0 ? (
          <div className='modal-table'>
            <table className='table table-hover font-sm data-table'>
              <thead className='table-header'>
                <tr>
                  <th>Classe/ID</th>
                  <th>Erro</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {state.modalData.map((registro, index) => (
                  <tr key={index}>
                    <td className='limited-cell'>
                      <h6>{registro.classe}</h6>
                      <p className='small text-nowrap'>{formatarData(registro.recepcao)}</p>
                    </td>
                    <td style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: registro.mensagem }} />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>Nenhum registro encontrado.</p>
        )}
      </Modal>
      {state.exclusaoEmAndamento && <div>Excluindo registros...</div>}
    </div>
  );
};

export default ListaRegistrosDev;
