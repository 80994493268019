import React from 'react';
import Routes from './routes';
import { Router } from 'react-router-dom'; 
import UserProvider from './context';
import { history } from './history';


function App() {
  return (
    <UserProvider>
      <div className="App">                
        <Router history={history}>                    
            <Routes />          
        </Router>
      </div>
    </UserProvider>
    
  );
}

export default App; 
