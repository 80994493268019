import React, { Component } from 'react';
import api from '../../services/api';
import Menu from '../menu';
import '../../styles.css'

export default class Listver extends Component {

    state = {
        _ver: [],
    }

    async componentDidMount() {
        this.getversao();
    }

    async getversao() {
        const response = await api.get('/sistemaversao', { auth: { username: 'Portal', password: 'Portal1163' }});    
        this.setState({ _ver: response.data });
    }
    
    render() {
        return (
            <div className=''>
                <Menu/>           
                <div className='container-sm'>
                    <br></br>
                    <h5>Versões em uso</h5> 
                    <br></br>
                     
                <table className="table table-hover font-sm">
                    <thead>
                        <tr>
                            <th scope="col">CNPJ</th>
                            <th scope="col">Nome</th>                                                        
                            <th scope="col">Sistema</th>                            
                            <th scope="col">Versão</th>
                            <th scope="col">Ult. Acesso</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {this.state._ver.map(ver => (
                            <tr key={ver.CNPJ}>
                                <td>{ver.cnpj}</td>                                
                                <td className='esquerda'>{ver.nome}</td>                                
                                <td className='esquerda'>{ver.descricao}</td>
                                <td>{ver.versao}</td>                                
                                <td className='datahora'>{ver.ultimoacesso}</td>                                                                
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            </div>
        )
    }
}
