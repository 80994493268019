import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Card from 'react-bootstrap/Card';
import Menu from '../menu';
import Zapi from '../../components/api/api';
import Zcompnfe from '../../components/api/compnfe';
import Zmonitoramento from '../monitoramento/monitoramento';
import ListaRegistros from '../pdvexception/list';
import ListaRegistrosDev from '../pdvexception/listdev';
import ZDashboard from '../monitoramento/dashboard';
import ZUpload from '../monitoramento/upload';
import ZDownload from '../monitoramento/download';
import ServidoresAWS from '../monitoramento/servidoresaws';
import ServidoresNFe from '../monitoramento/servidoresnfe';
import ServidoresNFCe from '../monitoramento/servidoresnfce';
import './App.css';  // Certifique-se de importar o CSS

Modal.setAppElement('#root');

export default function Principal() {
  const [showComponents, setShowComponents] = useState(false);

  useEffect(() => {
    setShowComponents(true);
  }, []);

  return (
    <div className="container-fluid">
      <Menu />
      <div className="sidebar">
        {showComponents && (
          <div>
            <div className="section">
              <div className="section-title">AWS Próprio</div>
              <div className="sidebar-bg">
                <ServidoresAWS />
              </div>
            </div>
            <div className="section">
              <div className="section-title">AWS Compsoft</div>
              <div className="sidebar-bg">
                <div className="item">
                  <div className="item-title">Compnfe/CTe</div>
                  <Zcompnfe />
                </div>
                <div className="item">
                  <div className="item-title">Zaniboni</div>
                  <Zapi />
                </div>
                <div className="item">
                  <div className="item-title">Monitor</div>
                  <Zmonitoramento />
                </div>
                <div className="item">
                  <div className="item-title">Dashboard</div>
                  <ZDashboard />
                </div>
                <div className="item">
                  <div className="item-title">Upload</div>
                  <ZUpload />
                </div>
                <div className="item">
                  <div className="item-title">Download</div>
                  <ZDownload />
                </div>
              </div>
            </div>
        {/*    <div className="section">
              <div className="section-title">Compnfe - Status </div>
              <div className="sidebar-bg">
                <ServidoresNFe />
              </div>
            </div>            
            <div className="section">
              <div className="section-title">NFCe - Status </div>
              <div className="sidebar-bg">
                <ServidoresNFCe />
              </div>
            </div>                        */}
          </div> 
        )}
      </div>
      <div className="main-content">
        <Card className="text-white bg-secondary">
          <Card.Body className="margem-0">
            <ListaRegistros />
          </Card.Body>
          <Card.Body className="margem-0">
            <ListaRegistrosDev />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
