import React, { Component } from 'react';
import axios from 'axios';
import Menu from '../menu';
import CharacteristicPopup from './CharacteristicPopup'; // Importe o novo componente de popup

class CsApiWithToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      authToken: null,
      searchQuery: '',
      isPopupOpen: false,
      selectedCharacteristics: [],
    };
  }

  capitalizeFirstLetters = (name) => {
    return name.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  componentDidMount() {
    this.loginAndFetchProducts();
  }

  loginAndFetchProducts = async () => {
    try {
      const response = await axios.post('https://csapi.compsoft.com.br/usuarios/login', {
        usuario: 'zaniboni',
        senha: 'zan@123',
      });

      const { token } = response.data;

      this.setState({ authToken: token });

      this.fetchAllProducts(token);
    } catch (error) {
      console.error(error);
    }
  };

  fetchAllProducts = async (authToken) => {
    try {
      const response = await axios.get('https://csapi.compsoft.com.br/produtos', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { data } = response;

      this.setState({ products: data });
    } catch (error) {
      console.error(error);
    }
  };

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  showCharacteristicsPopup = (characteristics) => {
    this.setState({
      isPopupOpen: true,
      selectedCharacteristics: characteristics,
    });
    console.log(characteristics);
  };

  closeCharacteristicsPopup = () => {
    this.setState({
      isPopupOpen: false,
      selectedCharacteristics: [],
    });
  };

  getRowClassName = (product) => {
    const className = product.desconto > 0 ? 'Promoção' : '';
    return className;
  };

  render() {
    const { products, searchQuery } = this.state;

    const filteredProducts = products.filter((product) =>
      product.nome.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalRecords = filteredProducts.length;

    return (
      <div className="container d-flex flex-column align-items-center">
        <Menu />
        <br />
        <br />
        <h2 className="mt-4 mb-2">Lista de Produtos</h2>
        <div className="mb-3">
          <form>
            <input
              type="text"
              className="form-control"
              placeholder="Pesquisar"
              value={searchQuery}
              onChange={this.handleSearchChange}
            />
          </form>
        </div>
        <p>Total de registros: {totalRecords}</p>
        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            <thead className="thead-dark">
              <tr>
                <th className="text-left">ID</th>
                <th className="text-left">EAN</th>
                <th className="text-left">Nome</th>
                <th className="text-left">Qtd</th>                
                <th className="text-left">Peso</th>
                <th className="text-left">Comprimento</th>
                <th className="text-left">Largura</th>
                <th className="text-left">espessura</th>
                <th className="text-right">Valor</th>
                <th className="text-right">Promocional</th>
                <th>Ativo</th>
                <th>Obs</th>
                <th>Caracteristicas</th> {/* Novo cabeçalho da coluna para o botão */}
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product) => (
                <tr key={product.id}>
                  <td className="text-left">{product.id}</td>
                  <td className="text-left">{product.ean}</td>
                  <td className="text-left">{this.capitalizeFirstLetters(product.nome)}</td>                  
                  <td className="text-right">{product.estoque}</td>
                  <td className="text-right">{product.peso}</td>                                    
                  <td className="text-right">{product.comprimento}</td>
                  <td className="text-right">{product.largura}</td>
                  <td className="text-right">{product.espessura}</td>
                  <td className="text-right">{product.valor}</td>
                  <td className="text-right">
                    {(product.valor - (product.valor / 100) * product.desconto).toFixed(2)}
                  </td>
                  <td>
                    <input type="checkbox" className="form-check-input" checked={product.ativo} disabled />
                  </td>
                  <td>{this.getRowClassName(product)}</td>
                  <td>
                    <button className='btn btn-primary' onClick={() => this.showCharacteristicsPopup(product.caracteristicas) }>
                      Caracteristicas
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {this.state.isPopupOpen && (
          <CharacteristicPopup
            characteristics={this.state.selectedCharacteristics}
            onClose={this.closeCharacteristicsPopup}
          />
        )}
      </div>
    );
  }
}

export default CsApiWithToken;
