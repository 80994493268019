import React from 'react';

const CharacteristicPopup = ({ characteristics, onClose }) => {
  const popupStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    padding: '30px', // Aumentei o padding para dar mais espaço
    border: '1px solid #ccc',
    zIndex: '1000',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: '60%', // Aumentei a largura do popup
  };

  const overlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Ajuste a opacidade conforme necessário
    zIndex: '999',
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
  };

  const tdStyle = {
    borderBottom: '1px solid #ddd',
    padding: '15px', // Aumentei o padding das células
  };

  const closeButtonStyle = {
    width: '100%',
    marginTop: '15px', // Adicionei um pouco de margem ao botão
  };

  return (
    <div>
      <div style={overlayStyle} onClick={onClose}></div>
      <div style={popupStyle}>
        <h3>Características</h3>
        <table style={tableStyle}>
          <tbody>
            {characteristics &&
              characteristics.map((characteristic, index) => (
                <tr key={index}>
                  <td style={tdStyle}>{characteristic.nome}</td>
                  <td style={tdStyle}>{characteristic.valor}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <button
          className="btn btn-primary"
          style={closeButtonStyle}
          onClick={onClose}
        >
          Fechar
        </button>
      </div>
    </div>
  );
};

export default CharacteristicPopup;
