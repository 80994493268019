import React, { useContext, useState } from 'react';
import auth from '../services/auth';
import { UserContext } from '../context';
import { history } from '../history';
import './login.css';

const Login = () => {
    const context = useContext(UserContext);
    const [myUser, setUser] = useState({ USERNAME: '', PASSWORD: '' });

    const handleInputChange = ev => {
        const { name, value } = ev.target;
        setUser(prevUser => ({ ...prevUser, [name]: value }));
    };

    const handleSubmit = ev => {
        ev.preventDefault();
        auth.post('/lic/login', myUser, { auth: { username: 'Portal', password: 'Portal1163' }})
        .then(res => {            
            localStorage.setItem('delphireact-token', res.data.token);
            localStorage.setItem('user-role', res.data.role); // Armazena a função do usuário
            localStorage.setItem('user-name', res.data.usuario);            
            console.log(res.data);            
            context.alterLogin(true, res.data.role);
            history.push('/');
        })
        .catch(error => {
            console.log(error);
            alert('Usuário ou Senha Inválidos');
        });
    };

    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <span className="login100-form-title p-b-26">
                        Compsoft
                    </span>
                    <form onSubmit={handleSubmit}>
                        <div className="wrap-input100 validate-input" data-validate="Insira nome de Usuário">
                            <input className="input100" type="text" name="USERNAME" placeholder="Usuário" onChange={handleInputChange} />
                        </div>
                        <div className="wrap-input100 validate-input">
                            <input className="input100" type="password" name="PASSWORD" placeholder="Senha" onChange={handleInputChange} />
                        </div>
                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button className="login100-form-btn">
                                    Login
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
