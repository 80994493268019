import React, { Component } from 'react';
import api from '../../services/api';
import Menu from '../menu';

export default class Listlic extends Component {
    state = {
        _lic: {},
        searchValue: '',
    }

    async componentDidMount() {
        this.getlic();
    }

    async getlic() {
        const response = await api.get('/lic', { auth: { username: 'Portal', password: 'Portal1163' } });

        const groupedLicenses = response.data.reduce((groups, lic) => {
            const nome = lic.nome;
            if (!groups[nome]) {
                groups[nome] = { cnpj: lic.cnpj, licenses: [] };
            }
            groups[nome].licenses.push(lic);
            return groups;
        }, {});

        // Ordenar grupos por nome
        const sortedGroups = Object.keys(groupedLicenses).sort((a, b) => a.localeCompare(b));
        const sortedLicenses = {};
        sortedGroups.forEach(nome => {
            sortedLicenses[nome] = groupedLicenses[nome];
        });

        this.setState({ _lic: sortedLicenses });
    }

    Status(dataexpira, expira) {
        if (expira === 'N') {
            return  <font color="blue">Nunca Expira</font> ;
        }
    
        var strData = dataexpira;
        var partesData = strData.split("/");
        var data = new Date(partesData[2], partesData[1], partesData[0]);
        strData = dataexpira;
        
        if (data > new Date()) strData   = <font color="green">{strData}</font>;
        if (data === new Date()) strData = <font color="orange">{strData}</font>;
        if (data < new Date()) strData   = <font color="red">{strData}</font>;
        
        return strData;
    }

    handleSearchChange = event => {
        const { value } = event.target;
        this.setState({ searchValue: value });
    }

    renderGroupedLicenses() {
        const { _lic, searchValue } = this.state;

        return (
            <div>
                <input
                    type="text"
                    placeholder="Buscar por nome"
                    value={searchValue}
                    onChange={this.handleSearchChange}
                />
                {Object.keys(_lic).map(nome => {
                    if (nome.toLowerCase().includes(searchValue.toLowerCase())) {
                        const { cnpj, licenses } = _lic[nome];
                        return (
                            <div key={nome}>
                                <h6>{nome}-{cnpj}</h6>
                                <table className="table table-hover font-sm esquerda">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sistema</th>
                                            <th scope="col">Vencimento</th>                                            
                                           {/* <th scope="col"></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {licenses.map(lic => (
                                            <tr key={lic.CNPJ}>
                                                <td>{lic.sistema}</td>
                                                <td> {this.Status(lic.dataexpira,lic.expira)}</td>                                                
                                                {/*<td><i className="fa fa-pen-to-square"></i></td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    }

    render() {
        return (
            <div className=''>
                <Menu />
                <br></br>
                <br></br>
                <div className='container-sm'>
                    <br></br>
                    <h5>Licenças</h5>
                    <br></br>
                    {this.renderGroupedLicenses()}
                </div>
            </div>
        )
    }
}




