import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Menu from '../menu';

const ListPDV = () => {
  const [pdv, setPDV] = useState([]);

  useEffect(() => {
    async function getPDV() {
      try {
        const response = await api.get('/pdv', { auth: { username: 'Portal', password: 'Portal1163' }});
        setPDV(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    getPDV();
  }, []);

  return (
    <div className=''>
      <Menu/>
      <div className='container'>
        <br />
        <h5>Lista de PDVs Monitorados</h5>
        <br />
        <div className="table-responsive">
          <table className="table table-hover font-sm esquerda">
            <thead>
              <tr>
                <th scope="col">CNPJ</th>
                <th scope="col">Nome</th>
                <th scope="col">PDV</th>
                <th scope="col">Status</th>
                <th scope="col">Ult. Acesso</th>
                <th scope="col">Série SAT</th>
                <th scope="col">Impressora Configurada</th>
              </tr>
            </thead>
            <tbody>
              {pdv.map(item => (
                <tr key={item.CNPJ}>
                  <td>{item.cnpj}</td>
                  <td>{item.nome}</td>
                  <td>{item.pdv}</td>
                  <td>{item.status === 'S' ? <img src="/on.png" alt="Em Uso"></img> : <img src="/off.png" alt="Fora de Uso"></img>}</td>
                  <td>{item.ultdata}</td>
                  <td>{item.sat}</td>
                  <td>{item.imp}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ListPDV;
